import React from "react"
import PropTypes from "prop-types"

import PromotedBankingProductCard from "@src/components/ProductCard/PromotedBankingProductCard"
import { formatCurrency } from "@src/lib/helpers"

const FACTS = [
  "apy",
  "monthlyFee",
  "minOpeningDeposit",
  "freeCashDeposits",
  "feeOverdraft",
]

const mapSectionEntryToProductCard = marketplaceEntity => {
  const {
    id,
    applyLink,
    prosBullets,
    consBullets,
    image,
    cta,
    product,
    institution,
    productType,
    reviewLink,
    displayName,
    placementDrivers,
    minOpeningDeposit,
    feeOverdraft,
    monetizable,
    federallyInsuredAnnotation,
    starRating,
  } = marketplaceEntity

  const finalPlacementDrivers = placementDrivers
    .map(x =>
      x.id === "apy" && !x.value
        ? { ...x, value: "N/A" }
        : { ...x, ...(x.valueTooltip && { lazyTooltip: true }) }
    )
    .concat([
      {
        description: null,
        id: "minOpeningDeposit",
        label: "Min. opening deposit",
        tooltip: null,
        value: formatCurrency(minOpeningDeposit),
        valueTooltip: null,
      },
      {
        description: product.freeCashDepositsDetails,
        id: "freeCashDeposits",
        label: "Free cash deposits",
        tooltip: null,
        value:
          typeof product.freeCashDeposits === "boolean"
            ? product.freeCashDeposits
              ? "Yes"
              : "No"
            : "N/A",
        valueTooltip: null,
      },
      {
        description: null,
        id: "feeOverdraft",
        label: "Overdraft fees",
        tooltip: null,
        value:
          typeof feeOverdraft === "number"
            ? formatCurrency(feeOverdraft)
            : "N/A",
        valueTooltip: null,
      },
    ])

  const finalCta = {
    ...cta,
    subtext:
      cta.subtext &&
      [cta.subtext, federallyInsuredAnnotation].filter(Boolean).join(", "),
  }

  return {
    applyUrl: applyLink,
    cta: finalCta,
    reviewUrl: reviewLink,
    prosBullets,
    consBullets,
    imgAlt: image.alt,
    imgSrc: image.source,
    name: displayName,
    facts: FACTS,
    placementDrivers: finalPlacementDrivers,
    starRating: starRating,
    marketplaceEntity: {
      id,
      product,
      institution,
      productType,
      monetizable,
    },
  }
}

const PromotedBankingSection = ({ offers }) => {
  return (
    <PromotedBankingProductCard
      offer={mapSectionEntryToProductCard(offers[0])}
    />
  )
}

PromotedBankingSection.propTypes = {
  offers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default PromotedBankingSection
