import {
  formatCurrency,
  getApplyOrRedirectUrl,
  isSectionActive,
  makeSortFunction,
} from "@src/lib/helpers"
import { findInPlacementDrivers } from "@src/lib/drivers"

const extractValue = {
  apy: row => {
    const placement = findInPlacementDrivers(row.placementDrivers, "apy")
    return parseFloat(placement.value?.replace(/^[^0-9.]/g, "")) || 0
  },
  minOpeningDeposit: row =>
    parseFloat(row.minOpeningDeposit.replace(/^[^0-9.]/g, "")) || 0,
  rating: row => row.starRating,
  bank: row => row.heading,
  freeCashDeposits: row => row.freeCashDeposits,
  feeOverdraft: row => row.feeOverdraft,
}

const sortFunction = makeSortFunction(extractValue)

const TABLE_COLUMNS = [
  {
    id: "bank",
    heading: "Bank/institution",
    sort: sortFunction([{ field: "bank" }]),
  },
  {
    id: "rating",
    heading: "NerdWallet rating",
    sort: sortFunction([
      { field: "rating", asc: false },
      { field: "apy" },
      { field: "bank" },
    ]),
  },
  { id: "monthlyFee", heading: "Monthly fee" },
  {
    id: "apy",
    heading: "APY",
    sort: sortFunction([{ field: "apy" }, { field: "bank" }]),
  },
  {
    id: "minOpeningDeposit",
    heading: "Min. opening deposit",
    sort: sortFunction([{ field: "minOpeningDeposit" }, { field: "bank" }]),
  },
  { id: "learnMore", heading: "Learn more" },
]

const TABLE_COLUMNS_WITH_FREE_CASH_DEPOSITS = [
  {
    id: "bank",
    heading: "Bank/institution",
    sort: sortFunction([{ field: "bank" }]),
  },
  {
    id: "rating",
    heading: "NerdWallet rating",
    sort: sortFunction([
      { field: "rating", asc: false },
      { field: "apy" },
      { field: "bank" },
    ]),
  },
  { id: "monthlyFee", heading: "Monthly fee" },
  {
    id: "apy",
    heading: "APY",
    sort: sortFunction([{ field: "apy" }, { field: "bank" }]),
  },
  {
    id: "freeCashDeposits",
    heading: "Free cash deposits",
    sort: sortFunction([
      { field: "freeCashDeposits", asc: false },
      { field: "bank" },
    ]),
  },
  { id: "learnMore", heading: "Learn more" },
]

const TABLE_COLUMNS_WITH_OVERDRAFT_FEES = [
  {
    id: "bank",
    heading: "Bank/institution",
    sort: sortFunction([{ field: "bank" }]),
  },
  {
    id: "rating",
    heading: "NerdWallet rating",
    sort: sortFunction([
      { field: "rating", asc: false },
      { field: "apy" },
      { field: "bank" },
    ]),
  },
  { id: "monthlyFee", heading: "Monthly fee" },
  {
    id: "apy",
    heading: "APY",
    sort: sortFunction([{ field: "apy" }, { field: "bank" }]),
  },
  {
    id: "feeOverdraft",
    heading: "Overdraft fee",
    sort: sortFunction([{ field: "feeOverdraft" }, { field: "bank" }]),
  },
  { id: "learnMore", heading: "Learn more" },
]

const MINI_TABLE_ROWS = [
  {
    id: "name",
    formatter: "accent",
  },
  {
    id: "monthlyFee",
    heading: "Monthly Fee",
  },
  {
    id: "apy",
    heading: "APY",
  },
  {
    id: "rating",
    heading: "NW rating",
    formatter: "rating",
  },
  {
    id: "cta",
    formatter: "cta",
  },
]

const getTableColumns = (withFreeCashDeposits, withOverdraftFees) => {
  if (withOverdraftFees) {
    return TABLE_COLUMNS_WITH_OVERDRAFT_FEES
  }

  if (withFreeCashDeposits) {
    return TABLE_COLUMNS_WITH_FREE_CASH_DEPOSITS
  }

  return TABLE_COLUMNS
}

const sortData = (data, sortBy, columns) => {
  if (!sortBy || sortBy === "bank") return data
  const sortFunction = columns.find(i => i.id === sortBy).sort
  const sortedData = [...data].sort(sortFunction)

  return sortedData
}

const filterActiveSection = sections => {
  const entries = sections.filter(isSectionActive).map(section => {
    const { marketplaceEntity } = section
    return {
      marketplaceEntity: {
        id: marketplaceEntity.id,
        product: marketplaceEntity.product,
        institution: marketplaceEntity.institution,
        productType: marketplaceEntity.productType,
        monetizable: marketplaceEntity.monetizable,
      },
      categoryName: section.categoryName,
      cta: marketplaceEntity.cta,
      federallyInsuredAnnotation: marketplaceEntity.federallyInsuredAnnotation,
      heading: marketplaceEntity.displayName,
      imgAlt: marketplaceEntity.image.alt,
      imgSrc: marketplaceEntity.image.sourceMedium,
      feeOverdraft: marketplaceEntity.feeOverdraft,
      freeCashDeposits: marketplaceEntity.product?.freeCashDeposits,
      freeCashDepositsDetails:
        marketplaceEntity.product?.freeCashDepositsDetails,
      placementDrivers: marketplaceEntity.placementDrivers,
      reviewLink: marketplaceEntity.reviewLink,
      applyUrl: getApplyOrRedirectUrl(section),
      starRating: marketplaceEntity.starRating,
      minOpeningDeposit: formatCurrency(marketplaceEntity.minOpeningDeposit),
    }
  })
  return entries
}

const columnsToSort = TABLE_COLUMNS.filter(i => i.sort).map(col => ({
  key: col.id,
  value: col.heading,
}))

const columnsWithFreeCashDepositsToSort =
  TABLE_COLUMNS_WITH_FREE_CASH_DEPOSITS.filter(i => i.sort).map(col => ({
    key: col.id,
    value: col.heading,
  }))

const columnsWithOverdraftFeesToSort = TABLE_COLUMNS_WITH_OVERDRAFT_FEES.filter(
  i => i.sort
).map(col => ({
  key: col.id,
  value: col.heading,
}))

const getColumnsToSort = (withFreeCashDeposits, withOverdraftFees) => {
  if (withOverdraftFees) {
    return columnsWithOverdraftFeesToSort
  }
  if (withFreeCashDeposits) {
    return columnsWithFreeCashDepositsToSort
  }

  return columnsToSort
}

export {
  getColumnsToSort,
  getTableColumns,
  sortFunction,
  columnsToSort,
  columnsWithOverdraftFeesToSort,
  sortData,
  filterActiveSection,
  TABLE_COLUMNS,
  TABLE_COLUMNS_WITH_FREE_CASH_DEPOSITS,
  TABLE_COLUMNS_WITH_OVERDRAFT_FEES,
  MINI_TABLE_ROWS,
}
