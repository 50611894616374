import React from "react"
import PropTypes from "prop-types"

import Category from "@src/components/roundup/Category"
import BankingProductCard from "@src/components/ProductCard/BankingProductCard"
import { formatCurrency, isSectionActive } from "@src/lib/helpers"

const FACTS_IDS = [
  "apy",
  "monthlyFee",
  "minOpeningDeposit",
  "freeCashDeposits",
  "feeOverdraft",
]

const MarketplaceProduct = props => {
  const { section, withProsAndCons } = props
  const { bottomLine } = section
  const {
    id,
    applyLink,
    prosBullets,
    consBullets,
    image,
    cta,
    product,
    institution,
    productType,
    reviewLink,
    displayName,
    placementDrivers,
    minOpeningDeposit,
    feeOverdraft,
    monetizable,
    federallyInsuredAnnotation,
    starRating,
  } = section.marketplaceEntity

  const finalPlacementDrivers = placementDrivers
    .map(x => (x.id === "apy" && !x.value ? { ...x, value: "N/A" } : x))
    .concat([
      {
        description: null,
        id: "minOpeningDeposit",
        label: "Min. opening deposit",
        tooltip: null,
        value: formatCurrency(minOpeningDeposit),
        valueTooltip: null,
      },
      {
        description: product.freeCashDepositsDetails,
        id: "freeCashDeposits",
        label: "Free cash deposits",
        tooltip: null,
        value:
          typeof product.freeCashDeposits === "boolean"
            ? product.freeCashDeposits
              ? "Yes"
              : "No"
            : "N/A",
        valueTooltip: null,
      },
      {
        description: null,
        id: "feeOverdraft",
        label: "Overdraft fees",
        tooltip: null,
        value:
          typeof feeOverdraft === "number"
            ? formatCurrency(feeOverdraft)
            : "N/A",
        valueTooltip: null,
      },
    ])

  const finalCta = {
    ...cta,
    subtext:
      cta.subtext &&
      [cta.subtext, federallyInsuredAnnotation].filter(Boolean).join(", "),
  }

  return (
    <BankingProductCard
      applyUrl={applyLink}
      bottomLine={bottomLine}
      cta={finalCta}
      reviewUrl={reviewLink}
      prosBullets={withProsAndCons ? prosBullets : null}
      consBullets={withProsAndCons ? consBullets : null}
      imgAlt={image.alt}
      imgSrc={image.source}
      name={displayName}
      facts={FACTS_IDS}
      placementDrivers={finalPlacementDrivers}
      starRating={starRating}
      marketplaceEntity={{
        id,
        product,
        institution,
        productType,
        monetizable,
      }}
    />
  )
}

MarketplaceProduct.propTypes = {
  withProsAndCons: PropTypes.bool,
  section: PropTypes.shape({
    bottomLine: PropTypes.shape({}).isRequired,
    marketplaceEntity: PropTypes.shape({
      starRating: PropTypes.string.isRequired,
      prosBullets: PropTypes.string.isRequired,
      consBullets: PropTypes.string.isRequired,
      image: PropTypes.shape({
        alt: PropTypes.string.isRequired,
        source: PropTypes.string.isRequired,
      }).isRequired,
      displayName: PropTypes.any.isRequired,
      applyLink: PropTypes.string.isRequired,
      reviewLink: PropTypes.string.isRequired,
      minOpeningDeposit: PropTypes.number,
      feeOverdraft: PropTypes.number,
      monetizable: PropTypes.string,
      cta: PropTypes.shape({
        link: PropTypes.string.isRequired,
        subtext: PropTypes.string,
        text: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      }).isRequired,
      federallyInsuredAnnotation: PropTypes.string,
      productType: PropTypes.any.isRequired,
      placementDrivers: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
          tooltip: PropTypes.string.isRequired,
        })
      ).isRequired,
      productLabel: PropTypes.string,
      id: PropTypes.string,
      product: PropTypes.shape({
        id: PropTypes.string,
        freeCashDeposits: PropTypes.bool,
        freeCashDepositsDetails: PropTypes.string,
      }),
      institution: PropTypes.shape({
        id: PropTypes.string,
      }),
    }).isRequired,
  }).isRequired,
}

MarketplaceProduct.defaultProps = {
  withProsAndCons: false,
}

const BankingProductSection = ({ sections, withProsAndCons }) => {
  const categories = [
    ...new Set(sections.map(({ categoryName }) => categoryName)),
  ]

  return (
    <>
      {categories.map(category => {
        const categorySections = sections.filter(
          section =>
            section.categoryName === category && isSectionActive(section)
        )
        return (
          <Category
            key={category}
            category={category}
            sections={categorySections}
            marketplaceProductComponent={MarketplaceProduct}
            withProsAndCons={withProsAndCons}
          />
        )
      })}
    </>
  )
}

BankingProductSection.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      categoryName: PropTypes.string.isRequired,
      marketplaceEntity: PropTypes.shape({}).isRequired,
    })
  ).isRequired,
  withProsAndCons: PropTypes.bool,
}

BankingProductSection.defaultProps = {
  withProsAndCons: false,
}

export default BankingProductSection
