import React from "react"
import PropTypes from "prop-types"

import Table, {
  DriverCell,
  EntityCell,
  StarRatingCell,
} from "@src/components/Table"
import TrackedCTA from "@src/components/TrackedCTA"
import { findInPlacementDrivers } from "@src/lib/drivers"
import { formatCurrency } from "@src/lib/helpers"
import { getTableColumns } from "./helper"

import styles from "./BankingTableSection.module.less"

const BankingTable = ({ entries, withFreeCashDeposits, withOverdraftFees }) => {
  const data = entries.map(entry => {
    const {
      heading,
      marketplaceEntity,
      cta,
      applyUrl,
      starRating,
      reviewLink,
      placementDrivers,
      federallyInsuredAnnotation,
      categoryName,
    } = entry

    const monthlyFee = findInPlacementDrivers(placementDrivers, "monthlyFee")
    const apy = findInPlacementDrivers(placementDrivers, "apy")
    const finalCta = {
      ...cta,
      subtext:
        cta.subtext &&
        [cta.subtext, federallyInsuredAnnotation].filter(Boolean).join(", "),
    }

    return {
      bank: (
        <EntityCell
          imgAlt={entry.imgAlt}
          imgSrc={entry.imgSrc}
          imgClassName={styles.image}
          text={heading}
          subNodes={
            <div className={styles.showOnMobileOnly}>
              {finalCta?.link && (
                <TrackedCTA
                  id={marketplaceEntity.id}
                  productType={marketplaceEntity.productType}
                  product={marketplaceEntity.product}
                  institution={marketplaceEntity.institution}
                  monetizable={marketplaceEntity.monetizable}
                  cta={finalCta}
                  ctaType="Banking Summary CTA"
                  href={applyUrl}
                />
              )}
            </div>
          }
        />
      ),
      rating: (
        <StarRatingCell
          rating={starRating}
          link={reviewLink}
          categoryName={categoryName && `Best for ${categoryName}`}
        />
      ),
      monthlyFee: monthlyFee && (
        <DriverCell
          description={monthlyFee.description}
          tooltip={monthlyFee.tooltip}
          value={monthlyFee.value}
          valueTooltip={monthlyFee.valueTooltip}
        />
      ),
      apy:
        apy && apy.value ? (
          <DriverCell
            description={apy.description}
            tooltip={apy.tooltip}
            value={apy.value}
            valueTooltip={apy.valueTooltip}
          />
        ) : (
          "N/A"
        ),
      minOpeningDeposit: entry.minOpeningDeposit,
      freeCashDeposits:
        typeof entry.freeCashDeposits === "boolean" ? (
          <DriverCell
            description={entry.freeCashDepositsDetails}
            value={entry.freeCashDeposits ? "Yes" : "No"}
          />
        ) : (
          "N/A"
        ),
      feeOverdraft:
        typeof entry.feeOverdraft === "number" ? (
          <DriverCell value={formatCurrency(entry.feeOverdraft)} />
        ) : (
          "N/A"
        ),
      learnMore: (
        <div className={styles.textCenter}>
          {finalCta?.link && (
            <TrackedCTA
              id={marketplaceEntity.id}
              productType={marketplaceEntity.productType}
              product={marketplaceEntity.product}
              institution={marketplaceEntity.institution}
              monetizable={marketplaceEntity.monetizable}
              cta={finalCta}
              ctaType="Banking Summary CTA"
              href={applyUrl}
            />
          )}
        </div>
      ),
    }
  })

  const columns = getTableColumns(withFreeCashDeposits, withOverdraftFees)

  return <Table columns={columns} data={data} stickyHeader />
}

BankingTable.propTypes = {
  entries: PropTypes.arrayOf(
    PropTypes.shape({
      marketplaceEntity: PropTypes.shape({
        id: PropTypes.string.isRequired,
        product: PropTypes.shape({}).isRequired,
        institution: PropTypes.shape({}).isRequired,
        productType: PropTypes.string.isRequired,
      }),
      categoryName: PropTypes.string.isRequired,
      cta: PropTypes.shape({
        link: PropTypes.string.isRequired,
        subtext: PropTypes.string,
        text: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      }).isRequired,
      federallyInsuredAnnotation: PropTypes.string,
      type: PropTypes.string.isRequired,
      heading: PropTypes.string.isRequired,
      imgAlt: PropTypes.string,
      imgSrc: PropTypes.string.isRequired,
      monetizable: PropTypes.string,
      placementDrivers: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
          description: PropTypes.string,
          tooltip: PropTypes.any,
          valueTooltip: PropTypes.any,
        })
      ).isRequired,
      reviewLink: PropTypes.string,
      applyUrl: PropTypes.string,
      starRating: PropTypes.number,
      minOpeningDeposit: PropTypes.number,
    })
  ).isRequired,
  withFreeCashDeposits: PropTypes.bool,
  withOverdraftFees: PropTypes.bool,
}

export default BankingTable
