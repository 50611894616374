import React from "react"
import PropTypes from "prop-types"

import ResponsiveDisplay from "@src/components/ResponsiveDisplay"
import TrackedCTA from "@src/components/TrackedCTA"
import ItemRating from "@src/components/ItemRating"

import {
  CardHeading,
  CardImageWrapper,
  FeaturedWrapper,
  CardContentWrapper,
  CtaWrapper,
  QuickFacts,
  CardProsCons,
} from "../cardSubComponents"

import styles from "./PromotedBankingProductCard.module.less"

const PromotedBankingProductCard = ({ offer }) => {
  const {
    prosBullets,
    consBullets,
    imgAlt,
    imgSrc,
    cta,
    reviewUrl,
    name,
    facts,
    placementDrivers,
    marketplaceEntity,
    starRating,
  } = offer

  const { id, productType, product, institution, monetizable } =
    marketplaceEntity

  const ctaButton = cta?.link && (
    <TrackedCTA
      buttonClassName={styles.cta}
      id={id}
      productType={productType}
      product={product}
      institution={institution}
      monetizable={monetizable}
      cta={cta}
      ctaType="Banking Promoted Card CTA"
      href={cta.link}
    />
  )

  const prosCons = (
    <CardProsCons pros={prosBullets} cons={consBullets} reviewUrl={reviewUrl} />
  )

  const driverByFacts = placementDrivers.filter(driver =>
    facts.some(fact => fact === driver.id)
  )

  return (
    <FeaturedWrapper>
      <CardImageWrapper
        imgAlt={imgAlt}
        imgSrc={imgSrc}
        heading={name}
        bottomContent={
          <ResponsiveDisplay customBreakpoints desktop>
            <CtaWrapper>{ctaButton}</CtaWrapper>
          </ResponsiveDisplay>
        }
      />
      <CardHeading heading={name}>
        {(!!starRating || (starRating && reviewUrl)) && (
          <ItemRating
            displayNumber
            horizontal
            href={reviewUrl}
            rating={starRating}
          />
        )}
      </CardHeading>

      <CtaWrapper>
        <ResponsiveDisplay customBreakpoints mobile tablet>
          {ctaButton}
        </ResponsiveDisplay>
      </CtaWrapper>

      <QuickFacts
        facts={facts}
        placementDrivers={driverByFacts}
        inlineDescription={false}
        columned
      />
      <CardContentWrapper accordion prosCons={prosCons} heading={name} />
    </FeaturedWrapper>
  )
}

PromotedBankingProductCard.propTypes = {
  offer: PropTypes.shape({
    benefits: PropTypes.shape({}),
    drawbacks: PropTypes.shape({}),
    prosBullets: PropTypes.arrayOf(PropTypes.string).isRequired,
    consBullets: PropTypes.arrayOf(PropTypes.string).isRequired,
    applyUrl: PropTypes.string.isRequired,
    imgAlt: PropTypes.string.isRequired,
    imgSrc: PropTypes.string.isRequired,
    reviewUrl: PropTypes.string,
    name: PropTypes.node.isRequired,
    bottomLine: PropTypes.shape({}).isRequired,
    starRating: PropTypes.number,
    cta: PropTypes.shape({
      link: PropTypes.string.isRequired,
      subtext: PropTypes.string,
      text: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired,
    placementDrivers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        tooltip: PropTypes.string.isRequired,
      })
    ).isRequired,
    facts: PropTypes.arrayOf(PropTypes.string).isRequired,
    marketplaceEntity: PropTypes.shape({
      id: PropTypes.string.isRequired,
      product: PropTypes.shape({}).isRequired,
      institution: PropTypes.shape({}).isRequired,
      productType: PropTypes.string.isRequired,
      monetizable: PropTypes.string,
    }).isRequired,
  }).isRequired,
}

export default PromotedBankingProductCard
