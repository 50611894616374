import React, { useState } from "react"
import PropTypes from "prop-types"

import { Box } from "@nerdwallet/currency"
import { Title } from "@nerdwallet/react-typography"
import Dropdown from "@src/components/Dropdown"
import TableFeatures from "@src/components/roundup/TableFeatures"
import BankingMiniTable from "./BankingMiniTable"
import BankingTable from "./BankingTable"

import {
  getColumnsToSort,
  sortData,
  filterActiveSection,
  getTableColumns,
} from "./helper"

import styles from "./BankingTableSection.module.less"

const BankingTableSection = ({
  title,
  sections,
  productDescription,
  sort,
  useMiniTable,
  withFreeCashDeposits,
  withOverdraftFees,
}) => {
  const columns = getTableColumns(withFreeCashDeposits, withOverdraftFees)

  const [sortBy, setSortBy] = useState(null)
  const entries = filterActiveSection(sections)
  const sortedEntries = sortData(entries, sortBy, columns)
  const displayMiniTable = useMiniTable
  const tableWrapperDisplay = displayMiniTable
    ? {
        mobile: "none",
        desktop: "block",
      }
    : {}

  return (
    <>
      <Title size="large" className={styles.title}>
        {title}
      </Title>
      <Box display={tableWrapperDisplay}>
        <TableFeatures
          sort={
            sort && (
              <Dropdown
                smallLegend
                boldLegend={false}
                header="Sort by"
                options={getColumnsToSort(
                  withFreeCashDeposits,
                  withOverdraftFees
                )}
                placeholder="Make selection"
                setUpdateAction={setSortBy}
              />
            )
          }
        />
        <BankingTable
          title={title}
          entries={sortedEntries}
          productDescription={productDescription}
          withFreeCashDeposits={withFreeCashDeposits}
          withOverdraftFees={withOverdraftFees}
        />
      </Box>
      {displayMiniTable && (
        <Box
          display={{ mobile: "block", desktop: "none" }}
          data-cy="banking-mini-table"
        >
          <Box marginTop="3-half">
            <BankingMiniTable entries={sortedEntries} />
          </Box>
        </Box>
      )}
    </>
  )
}

BankingTableSection.propTypes = {
  title: PropTypes.string.isRequired,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      marketplaceEntity: PropTypes.shape({
        displayName: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  productDescription: PropTypes.string.isRequired,
  sort: PropTypes.bool,
  useMiniTable: PropTypes.bool,
  withFreeCashDeposits: PropTypes.bool,
  withOverdraftFees: PropTypes.bool,
}

BankingTableSection.defaultProps = {
  sort: false,
  useMiniTable: false,
  withFreeCashDeposits: false,
  withOverdraftFees: false,
}

export default BankingTableSection
