import React from "react"
import PropTypes from "prop-types"

import Lazy from "react-lazyload"
import Type, { Title } from "@nerdwallet/react-typography"

import Column from "@src/components/Columns/Column"
import Columns from "@src/components/Columns/Columns"

import { matchesMonetizable } from "@src/lib/helpers"

import styles from "./TiledProductCards.module.less"

const TileImage = ({ src, alt }) => (
  <Lazy offset={100}>
    <img
      className={styles.image}
      alt={alt}
      src={src}
      data-cy="tile-product-card-image"
    />
  </Lazy>
)

TileImage.propTypes = {
  alt: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
}

const TileInfo = ({ heading, productName, link, isMonetizable, ctaType }) => (
  <>
    <Type bold>{heading}</Type>
    {link ? (
      <a
        className={styles.link}
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        data-cta-type={ctaType ?? "Tiled Product Card"}
        {...(isMonetizable && { "data-mclick": true })}
      >
        {productName}
      </a>
    ) : (
      productName
    )}
  </>
)

TileInfo.propTypes = {
  heading: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  link: PropTypes.string,
  isMonetizable: PropTypes.bool,
  ctaType: PropTypes.string,
}

const Tile = ({ children }) => {
  return <div className={styles.tile}>{children}</div>
}

Tile.propTypes = {
  children: PropTypes.node.isRequired,
}

const TiledProductCards = ({ heading, sections, ctaType }) => {
  return (
    <div className={styles.wrapper} data-cy="tiled-product-cards">
      <Title className={styles.heading} size="medium">
        {heading}
      </Title>
      <Columns gap>
        {sections.map(({ categoryName, marketplaceEntity }) => {
          return (
            <Column key={categoryName} width={{ mobile: 12, desktop: 6 }}>
              <Tile>
                {marketplaceEntity.image?.source && (
                  <div>
                    <TileImage
                      src={marketplaceEntity.image.source}
                      alt={
                        marketplaceEntity.image.alt ||
                        `${marketplaceEntity.displayName} Logo`
                      }
                    />
                  </div>
                )}
                <div>
                  <TileInfo
                    heading={categoryName}
                    productName={marketplaceEntity.displayName}
                    link={marketplaceEntity.cta?.link}
                    ctaType={ctaType}
                    isMonetizable={matchesMonetizable(
                      marketplaceEntity.monetizable
                    )}
                  />
                </div>
              </Tile>
            </Column>
          )
        })}
      </Columns>
    </div>
  )
}

TiledProductCards.propTypes = {
  heading: PropTypes.string,
  ctaType: PropTypes.string,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      categoryName: PropTypes.string,
      marketplaceEntity: PropTypes.shape({
        displayName: PropTypes.string.isRequired,
        image: PropTypes.shape({
          alt: PropTypes.string.isRequired,
          source: PropTypes.string.isRequired,
        }),
        cta: PropTypes.shape({
          link: PropTypes.string.isRequired,
        }).isRequired,
        monetizable: PropTypes.string,
      }).isRequired,
    })
  ).isRequired,
}

TiledProductCards.defaultProps = {
  heading: "Our Nerdy picks for",
}

export default TiledProductCards
