import React from "react"
import PropTypes from "prop-types"
import { findInPlacementDrivers } from "@src/lib/drivers"
import MiniTable from "@src/components/MiniTable"
import { MINI_TABLE_ROWS } from "./helper"

const BankingMiniTable = ({ entries }) => {
  const data = entries.map(banking => ({
    ...banking,
    id: banking.marketplaceEntity.id,
    name: banking.marketplaceEntity.institution.name,
    category: banking.categoryName && `Best for ${banking.categoryName}`,
    monthlyFee: findInPlacementDrivers(banking.placementDrivers, "monthlyFee")
      .value,
    apy: findInPlacementDrivers(banking.placementDrivers, "apy").value,
    rating: {
      rating: {
        rating: banking.starRating,
        link: banking.reviewLink,
        categoryName:
          banking.categoryName && `Best for ${banking.categoryName}`,
      },
      marketplaceEntity: banking.marketplaceEntity,
    },
    cta: {
      cta: {
        text: banking.cta.text,
        link: banking.cta.link,
        type: banking.cta.type,
      },
      marketplaceEntity: banking.marketplaceEntity,
    },
  }))

  return <MiniTable rows={MINI_TABLE_ROWS} data={data} />
}

BankingMiniTable.propTypes = {
  entries: PropTypes.arrayOf(
    PropTypes.shape({
      marketplaceEntity: PropTypes.shape({
        id: PropTypes.string.isRequired,
        product: PropTypes.shape({}).isRequired,
        institution: PropTypes.shape({}).isRequired,
        productType: PropTypes.string.isRequired,
      }),
      categoryName: PropTypes.string.isRequired,
      cta: PropTypes.shape({
        link: PropTypes.string.isRequired,
        subtext: PropTypes.string,
        text: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      }).isRequired,
      federallyInsuredAnnotation: PropTypes.string,
      type: PropTypes.string.isRequired,
      heading: PropTypes.string.isRequired,
      imgAlt: PropTypes.string,
      imgSrc: PropTypes.string.isRequired,
      monetizable: PropTypes.string,
      placementDrivers: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
          description: PropTypes.string,
          tooltip: PropTypes.any,
          valueTooltip: PropTypes.any,
        })
      ).isRequired,
      reviewLink: PropTypes.string,
      applyUrl: PropTypes.string,
      starRating: PropTypes.number,
      minOpeningDeposit: PropTypes.number,
    })
  ).isRequired,
}

export default BankingMiniTable
